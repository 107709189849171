import BaseExtension from "@/shared/BaseExtension"

export default BaseExtension.extend({
	methods: {
		focusInput() {
		  this.$refs.focus.focus();
		},
	},

	
})
