<template>
  <b-form @submit.stop.prevent="onSubmit" @keyup="onChange" :novalidate="true">
    <b-card no-body class="shadow-lg" border-variant="primary">
        <div slot="header">
          <strong>Company Form</strong>
        </div>
        <b-card-body>
          <b-form-group
            label="code"
            label-for="code"
            :label-cols="2"
            :invalid-feedback="form.errors.get('code')"
            :state="form.errors.has('code')"
            >
            <b-form-input disabled id="code" type="text" autocomplete="name" placeholder="Bedrijfs code"v-model="form.code">
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="name *"
            label-for="companyname"
            :label-cols="2"
            :invalid-feedback="form.errors.get('name')"
            :state="form.errors.has('name')" 
            >
            <b-form-input id="name" type="text" autocomplete="companyname" placeholder="Name of the company" v-model="form.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Addres"
            label-for="address"
            :label-cols="2"
            :invalid-feedback="form.errors.get('address')"
            :state="form.errors.has('address')"
            >
            <b-form-input id="address" type="text" autocomplete="name" placeholder="Address" v-model="form.address"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col sm="6">
              <b-form-group
              label="City"
              label-for="city"
              :label-cols="4"
              :invalid-feedback="form.errors.get('city')"
              :state="form.errors.has('city')"
              >
              <b-form-input id="city" type="text" placeholder="Name of the city" v-model="form.city"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="State"
                label-for="state"
                :label-cols="2"
                :invalid-feedback="form.errors.get('state')"
                :state="form.errors.has('state')"
                >
                <b-form-input id="state" type="text" autocomplete="name" placeholder="State" v-model="form.state"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            label="Country"
            label-for="country"
            :label-cols="2"
            :invalid-feedback="form.errors.get('country')"
            :state="form.errors.has('country')"
            >
            <b-form-input id="country" type="text" placeholder="Country" v-model="form.country"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Telephone number"
                label-for="Tel"
                :label-cols="4"
                :invalid-feedback="form.errors.get('phone_number')"
                :state="form.errors.has('phone_number')"
                >
                <b-form-input id="Tel" type="text" placeholder="Telephone Number" v-model="form.phone_number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Second number"
                label-for="alt_phone_number"
                :label-cols="2"
                :invalid-feedback="form.errors.get('alt_phone_number')"
                :state="form.errors.has('alt_phone_number')"
                >
                <b-input-group>
                  <b-form-input id="alt_phone_number" type="text" placeholder="Alternate phone number" v-model="form.alt_phone_number"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>          
          </b-row>
          <b-form-group
            label="Email address"
            label-for="ClientEmail"
            :label-cols="2"
            :invalid-feedback="form.errors.get('email_address')"
            :state="form.errors.has('email_address')"
            >
            <b-form-input id="ClientEmail" type="text" autocomplete="name" placeholder="Company Email"v-model="form.email_address"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Internet address"
            label-for="ClientWebpage"
            :label-cols="2"
            :invalid-feedback="form.errors.get('webpage')"
            :state="form.errors.has('webpage')"
            >
            <b-form-input id="ClientWebpage" type="text" autocomplete="name" placeholder="Webpage address" v-model="form.webpage"></b-form-input>
          </b-form-group>
        </b-card-body>
        <b-card-footer>
          <b-button v-if="form.id" size="sm" variant="danger" v-b-modal="'confirm'+form.id"><i class="fa fa-trash" aria-hidden="true"></i> Remove</b-button>
          <b-modal 
            :id="'confirm'+form.id" 
            size="sm"
            :centered="true"
            button-size="lg"
            :hide-header="true"
            cancel-title="No"
            ok-title="Yes"
            auto-focus-button="cancel"
            @ok="removeCompany"
            >
            Are you sure you want to remove this Company?
          </b-modal>
          <b-button v-if="isChange" class=" pull-right" type="submit" size="sm" variant="success"><i class="fa fa-check-circle-o" aria-hidden="true"></i> {{form.id? 'Update':'Save and Continue'}}</b-button>
          <b-button class="pull-right mx-2" @click="onResetCompany" size="sm" variant="danger"><i class="fa fa-backward"></i> Reset Form</b-button>
          <b-button v-if="isEdit" class=" pull-right" size="sm" variant="primary" :to="`/company/${companyId}/devices`"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Devices</b-button>
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'

export default AdminExtension.extend({
    name: 'CompanyModify',
    props: {
      companyId: {
        type: String,
        required: false,
      }
    },
    data() {
        return {
            isLocked: false,
            isSaved: true,
            companies: [],
            form: new Form({
                    id: null,
                    consistency_token: null,
                    code: null,
                    name: null,
                    phone_number: null,
                    alt_phone_number: null,
                    email_address: null,
                    webpage: null,
                    address: null,
                    city: null,
                    state: null,
                    country: null,
                }),
            isEdit: false,
            isChange: false,
        }
    },
    methods: {
        ...mapActions("common", ['generateCode']),
        ...mapActions("meds2go/company", ['companyDetail', 'companyCreate', 'companyRemove', 'companyUpdate']),
        loadData(){
            if (this.companyId > 0){
              this.companyDetail(this.companyId).then(response => {
                // console.log(response)
                this.form.set(response)
              }).catch(errors => {
                console.log(errors)
              })
              this.isEdit = true
            }else {
              this.generateCode({generate_code:'company'}).then(response=> {
                this.form.code = response
              })
            }
        },
        onSubmit() {
            if(this.isEdit){
                this.companyUpdate(this.form.data()).then(response => {
                    this.form.set(response)
                }).catch(errors => {
                    this.form.errors.record(errors)
              })
            }   
            else {
                this.companyCreate(this.form.data()).then(response => {
                    this.form.reset()
                    this.$router.push(`/company/${response.id}/devices`)                    
                }).catch(errors => {
                    this.form.errors.record(errors)
                })

            }
        },
        onResetCompany(){
            this.form.reset(['code'])
        },
        onChange(){
            this.isChange = true
        },
        removeCompany(){
            this.companyRemove({id: this.form.id, consistency_token: this.form.consistency_token, status: false}).
              then(this.$router.push("/dashboard")).catch(errors => {
                    this.form.errors.record(errors)
            })
      },
    },
    mounted() {
        this.loadData()
    }
})
</script>
