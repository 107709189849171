import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            isBusy: false
        }
    },
    methods: {
        loading() {
            this.isBusy = true
        },
        stopLoading() {
            this.isBusy = false
        }
    }
})